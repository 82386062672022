<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :fields-time-line="fieldsTimeLine"
      :isedit="false"
      :isdelete="model.status == 'created' && jobTitle.settings.includes('vehicle.getback.delete')"
      :is-action="model.status == 'created'"
      @readData="showData"
    >

      <template slot="buttonaction">
        <b-button
          v-if="jobTitle.settings.includes('vehicle.getback.approve')"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="action('approve')"
        >
          <span>Setujui</span>
        </b-button>
        <b-button
          v-if="jobTitle.settings.includes('vehicle.getback.reject')"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="action('reject')"
        >
          <span>Batalkan</span>
        </b-button>
      </template>
    </base-info>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <h3 class="font-weight-bolder">
          Daftar Perawatan
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          />

        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV3.vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment' 

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay,
    BButton
  },

  data() {
    return {
      dataurl:"/vehicle-getback",
      baseroute:"vehicle-getback",
      fields: [
        { key: 'maintenance.vehicle.number', label: 'Nomor Polisi', bold:true},
        { key: 'maintenance.code', label: 'Kode' },
        { key: 'maintenance.name', label: 'Perawatan' },
        { key: 'maintenance.workshop.name', label: 'Bengkel' },
        { key: 'created_at', label: 'Tgl Permintaan' , type:'date'},
        // { key: 'date', label: 'Tgl Pengambilan' , type:'date'},
        { key: 'status', label: 'Status', type:'spanKey', data:{created:{color:'bg-warning',value:'Pengajuan'}, approved:{color:'bg-success',value:'Disetujui'}, rejected:{color:'bg-danger',value:'Dibatalkan'}} },
        // { key: 'author.name', label: 'Pemesanan' },
        // { key: 'received_date', label: 'Tgl Persetujuan' , type:'date'},
        // { key: 'destination.name', label: 'Cabang Tujuan' },
      ],
      fieldsTable: [
        // { key: 'activity_type', label: 'Kegiatan'},
        { key: 'item.name', label: 'Perawatan' },
        { key: 'item.cost', label: 'Biaya' },
        { key: 'qty', label: 'Qty'},
        { key: 'item.interval', label: 'Interval'},
        { key: 'item.type', label: 'Type'},
      ],
      items:[],
      fieldsTimeLine:[],
      loading:false,
      model:[],
      jobTitle: {
        settings: [],
      },
    }
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.$refs.baseinfo.fetchData()
      }
    }
  },
  methods: {
    fetchData(id){
      this.$http.get('maintenance/'+id).then(res=>{
        this.items = res.data.details
      })

      this.$http.get('auth/me').then( res => {
        for (let setting of res.data.contact.job_title.settings)
        {
          if (setting.approval && setting.module){
            this.jobTitle.settings.push(setting.module.code);
          }
        }
      })
    },
    showData(result){
      this.model = result
      this.fetchData(result.maintenance.id)
      if(result.tracks){
        this.fieldsTimeLine = []
        for(let i=0; i<result.tracks.length; i++){
          this.fieldsTimeLine.push({
            title:result.tracks[i]['description'],
            description:'Diproses pada '+moment(result.tracks[i]['created_at']).format('DD MMMM YYYY HH:mm:ss')
          })
        }
        this.fieldsTimeLine.is_reverse = true
      }

    },
    action(action){
      const id = this.$route.params.id
      
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/'+action)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>
